@import "~client_core/components/collapsibleItem";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size}, {
            border-width: 1px 0 1px 0;
            &:not(:last-child) {
                border-bottom: 1px solid $collapsible-border-color;
            }
            .card-header {
                .title,
                .card-title,
                button.title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                    @include headerOrBanner($collapsible-title-header-or-banner, $collapsible-title-header-value);
                }
            }

        }
    }
}
