@import "~client_core/components/formFields";

.custom-select {
    background-image: url(../images/icons/icon-expand.svg);
    &.is-invalid {
        background-image: url(../images/icons/icon-expand.svg);
    }
}

select.form-control {
    padding-top: 0;
    padding-bottom: 0;
}


.custom-checkbox {
    .custom-control-input {
        &:checked ~ .custom-control-label:before,
        &:checked:disabled ~ .custom-control-label:before {
            border-color: map-get($content, primary);
        }
    }
}
.custom-radio {
    .custom-control-input {
        &:checked ~ .custom-control-label:before,
        &:checked:disabled ~ .custom-control-label:before {
            border-color: $radiocheck-border-color-selected;
        }
    }
}

.quantity-stepper {
    border: 1px solid transparent;
    input, button {
        background: map-get($bg, secondary);
    }
    &:hover {
        border: 1px solid map-get($border, primary);
    }
}