@import "~client_core/product/grid";

.refinement-bar {
    .secondary-bar {
      margin-bottom: 0;
      .secondary-bar-links {
        padding: 12px 0;
        text-decoration: underline;
      }
    }
    &:not([class*="refinement-bar-horizontal"]) {
      margin-top: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 12px;
      }
    }
  }
  
  .refinements {
    .refinement:not(.aside) {
      border-bottom: 1px solid map-get($border, tertiary);
      &.card {
        .card-header {
          .title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            justify-content: flex-start;
            &:after {
              opacity: 1;
            }
          }
          ~ .card-body {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
    .values.content {
      a {
        text-decoration: none;
      }
      padding: 0 0 0 $spacer * 2;
      .values.content {
        padding-left: 24px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .refinement.active {
      &.refinement-category .values {
        button {
          padding: $spacer 0;
        }
      }
      button.card-title {
        padding-bottom: 24px;
      }
    }
    .refinement.active .values {
      button {
        padding: $spacer-sm 0;
        span {
          text-decoration: none;
        }
        &.selected {
            border-bottom: 1px solid map-get($border, tertiary);
            width: 100%;
            margin-bottom: $spacer-sm;
        }
      }
      .swatches-vertical {
        button {
          border-bottom: none;
          padding: 6px 0;
        }
      }
      .values li {
        padding-top: 6px;
        padding-bottom: 6px;
        button {
          border-bottom: none;
          padding: 0;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .filter-bar {
    li {
      &.filter-value {
        button {
          font-size: $product-filter-font-size !important;
          @include icon($icon-close-small, after, $product-filter-icon-size);
          &:hover {
            border-color: map-get($border, primary);
          }
        }
      }
    }
  }
  
  .refinements .collapsible-xl .title::after, 
  .collapsible-xl .title.card-title::after {
    left: 5px;
  }
