@import "~client_core/helperClasses";

// Generate navigation classes, e.g. n1, .n2
@each $value in $navigation {
    $navigationIndex: index($navigation, $value);
    .#{nth($value, 1)} {
        @include navigation($navigationIndex);
    }
}

.blend-mode-multiply {
    mix-blend-mode: multiply;
}