@import "~client_core/components/header";
.header-banner {

    .header-banner-column-3 {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;

        .custom-dropdown {
            padding: $spacer-sm 0;
        }
    }
}
