@import "~client_core/components/footer";
footer {
    .footer-item {
        &.collapsible-sm {
            .title {
                @include header(4);
                &:after {
                    top: 22px;
                }
            }
            .title,
            .content {
                    background: transparent;
            }
            &:not(:last-child) {
                border-color: transparent;
            }
        }
    }
    .email-signup-form {
        .form-control {
            color: map-get($content, inverse);
            &::placeholder {
                color: map-get($content, inverse);
            }
            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px map-get($bg, inverse) inset;
                -webkit-text-fill-color: map-get($content, inverse);
                border: none;
            }
        }
        .inline-btn {
            border-color: map-get($content, inverse);
            &:hover,
            &:focus {
                border-color: map-get($content, inverse);
            }
        }

        .email-description {
            @include header(4);
            color: map-get($content, primary);
            margin-bottom: 8px;
        }

        .btn {
            @include icon($icon-arrow-top, after, 12px, map-get($content, inverse));
            &::after {
                transform: rotate(90deg);
            }
            &::before {
                content:'';
            }
        }
    }
}

