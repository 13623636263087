// Locally stored fonts (must be placed in static/fonts folder)
// Change this one variable, the rest should be handled in client core
// For italic fonts set the object key to 'normal'
$local-fonts: (
    "Inter" : (
        700: "Inter-Bold",
        600: "Inter-SemiBold",
        400: "Inter-Medium"
    )
);

@import "~client_core/utilities/fonts";